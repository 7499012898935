<template>
  <div style="height: 100%; background-color: #FFFFFF">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-saveBack="headSave(true)"
      @head-preview="preview"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <el-form label-width="100px" ref="dataForm" :model="dataForm" style="margin-top: 12px;width: calc(100% - 5px)"
                 :rules="rules"
                 :disabled="formType == 'view' || formType == 'alter'">
          <el-row>
            <el-col :span="8">
              <el-form-item label="模板编码" prop="templateCode">
                <el-input v-model="dataForm.templateCode"
                          placeholder="系统自动生成"
                          disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="模板名称" prop="templateName">
                <el-input v-model="dataForm.templateName"
                          placeholder="请输入模板名称"
                          maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="状态" prop="templateStatus">
                <el-select v-model="dataForm.templateStatus" placeholder="请选择状态" disabled>
                  <el-option
                    v-for="item in publishStatusOption"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="编制人" prop="weaveUser">
                <el-input v-model="dataForm.weaveUser" disabled placeholder="系统自动生成"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="编制单位" prop="weaveDept">
                <el-input v-model="dataForm.weaveDept" disabled placeholder="系统自动生成"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="使用级别" prop="applicableLevels">
                <el-radio-group v-model="dataForm.applicableLevels">
                  <el-radio :label="item.dictKey" v-for="(item,index) in usageLevelOption" :key="index">
                    {{ item.dictValue }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row
          style="display:flex;align-items:center;width: 100%;border-bottom: 1px solid #cccccc;padding:9px 0 8px 0">
          <el-col span="14" style="float: left;display:flex;align-items:center;height:28px">
            <div class="formTopic">模板信息</div>
          </el-col>
          <el-col span="10">
            <div style="float: right;">
              <el-button size="mini" type="button" @click="addTable" v-if="formType != 'view'">新增</el-button>
            </div>
          </el-col>
        </el-row>
        <div
          style="
                  padding-top: 12px;
                  display: flex;
                  justify-content: space-between;
                  height: calc(100% - 172px);
                "
        >
          <div class="leftClass">
            <el-button
              v-if="formType != 'view'"
              type="primary"
              size="medium"
              icon="el-icon-circle-plus-outline"
              style="width: 100%"
              @click="addClass"
            >添加分类
            </el-button
            >
            <div class="classDataBox">
              <div
                :class="classActive == index?'classActive':''"
                class="classLittle"
                v-for="(item, index) in classList"
                :key="index"
                @click="chooseTableData(item, index)"
              >
                <!--                <span style="white-space: normal;text-overflow: ellipsis;overflow: hidden;">{{ item.groupName }} - {{ item.columnNum }}列</span>-->
                <div style="display: flex;width: 100%;">
                  <div
                    style="width: calc(100% - 45px);white-space: normal;text-overflow: ellipsis;overflow: hidden;text-align: right">
                    {{ item.groupName }}
                  </div>
                  <div style="width: 45px;flex-shrink: 0"> - {{ item.columnNum }}列</div>
                </div>
                <i
                  class="el-icon-edit"
                  style="line-height: unset; color: #e6a23c"
                  v-if="formType == 'add' || formType == 'edit' || (formType == 'alter' && !item.id)"
                  @click.stop="editClass(item,index)"
                ></i>
                <i
                  class="el-icon-delete"
                  style="line-height: unset; color: #f56c6c"
                  v-if="formType == 'add' || formType == 'edit' || (formType == 'alter' && !item.id)"
                  @click.stop="deleteClass(index)"
                ></i>
              </div>
            </div>
          </div>
          <div class="rightTable">
            <el-table
              :data="properties"
              border
              ref="table"
              size="medium"
              style="width: 100%; height: 100%"
            >
              <el-table-column
                prop="date"
                label="排序"
                align="center"
                type="index"
                width="80"
                fixed
              >
              </el-table-column>
              <el-table-column
                prop="fieldCode"
                align="center"
                width="160"
              >
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    <span style="color: #F56C6C">*</span>数据项编号
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-input
                    :disabled="true"
                    v-model="scope.row.fieldCode"
                    clearable
                    maxlength="25"
                    placeholder="请输入数据项编号"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="fieldName"
                align="center"
                width="160"
              >
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    <span style="color: #F56C6C">*</span>数据项名称
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-input
                    :disabled="formType == 'view' || (formType == 'alter' && scope.row.id)"
                    v-model="scope.row.fieldName"
                    clearable
                    maxlength="40"
                    placeholder="请输入数据项名称"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="type" align="center" width="120">
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    <span style="color: #F56C6C">*</span>类型
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-select
                    :disabled="formType == 'view' || (formType == 'alter' && scope.row.id)"
                    v-model="scope.row.type"
                    placeholder="请选择类型"
                  >
                    <el-option
                      v-for="item in typeOptions"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="isEnable"
                align="center"
                width="120"
              >
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    <span style="color: #F56C6C">*</span>是否必填
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-select
                    :disabled="formType == 'view' || (formType == 'alter' && scope.row.id)"
                    v-model="scope.row.isEnable"
                    placeholder="请选择是否必填"
                  >
                    <el-option
                      v-for="item in yesOrNoOptions"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="isSummary"
                align="center"
                width="120"
              >
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    <span style="color: #F56C6C">*</span>是否汇总
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-select
                    :disabled="formType == 'view' || scope.row.type == '3' || scope.row.type == '4' || scope.row.type == '5'  || (formType == 'alter' && scope.row.id)"
                    v-model="scope.row.isSummary"
                    placeholder="请选择是否汇总"
                  >
                    <el-option
                      v-for="item in yesOrNoOptions"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="inputPrompt"
                label="输入提示"
                align="center"
                width="150"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.inputPrompt"
                    clearable
                    maxlength="200"
                    :disabled="formType == 'view' || (formType == 'alter' && scope.row.id)"
                    placeholder="请输入输入提示"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="inputLength"
                label="录入长度"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.inputLength"
                    clearable
                    maxlength="200"
                    :disabled="formType == 'view' || scope.row.type != '3'  || (formType == 'alter' && scope.row.id)"
                    placeholder="请输入录入长度"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="dictionaryCode"
                label="字典编码"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  <el-input
                    :disabled="formType == 'view'||scope.row.type != '5'  || (formType == 'alter' && scope.row.id)"
                    v-model="scope.row.dictionaryCode"
                    clearable
                    maxlength="40"
                    placeholder="请输入字典编码"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="inputWay"
                align="center"
                width="120"
              >
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    <span style="color: #F56C6C">*</span>输入方式
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-select
                    :disabled="formType == 'view' || scope.row.type == '3' || scope.row.type == '4' || scope.row.type == '5' || (formType == 'alter' && scope.row.id)"
                    v-model="scope.row.inputWay"
                    placeholder="请选择输入方式"
                  >
                    <el-option
                      v-for="item in inputWayOptions"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="inputWay"
                align="center"
                width="120"
              >
                <template slot="header" slot-scope="scope">
                  <div style="display: flex;justify-content: center">
                    单位
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-select
                    :disabled="formType == 'view' || scope.row.type == '1' || scope.row.type == '2' ||(formType == 'alter' && scope.row.id)"
                    v-model="scope.row.unit"
                    placeholder="请选择单位"
                  >
                    <el-option
                      v-for="item in unit"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="calculationRules"
                label="计算公式"
                align="center"
                width="350"
              >
                <template slot-scope="scope">
                  <el-input
                    :disabled="formType == 'view' || scope.row.type == '3' || scope.row.type == '4' || scope.row.type == '5'  || (formType == 'alter' && scope.row.id)||scope.row.inputWay == '1'"
                    v-model="scope.row.calculationRules"
                    clearable
                    maxlength="40"
                    placeholder="示例:#{a}+#{b}"
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" v-if="formType != 'view'" width="150" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    v-if="(formType == 'edit' && scope.row.isStatus=='0')||(formType == 'add' && scope.row.isStatus=='0')|| (formType == 'alter' && !scope.row.id) "
                    type="text"
                    @click="displayTable(scope.row, scope.$index)"
                  >显示
                  </el-button>
                  <el-button
                    v-if="(formType == 'add' && scope.row.isStatus=='1')|| (formType == 'edit' && scope.row.isStatus=='1') || (formType == 'alter' && !scope.row.id)"
                    type="text"
                    @click="hideTable(scope.row, scope.$index)"
                  >隐藏
                  </el-button>
                  <el-button
                    type="text"
                    @click="addTable(scope.row, scope.$index)"
                  >新增
                  </el-button>
                  <el-button
                    v-if="formType == 'add' || formType == 'edit' || (formType == 'alter' && !scope.row.id)"
                    type="text"
                    @click="deleteTable(scope.row, scope.$index)"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <CommonDialog v-if="classShow" width="30%" :dialogTitle="classTitle" @cancel="classShow = false" @confirm="submitClass">
      <el-form ref="classForm" :model="classForm" label-width="80px" :rules="classRules">
        <el-form-item label="分类名称" prop="groupName">
          <el-input
            v-model="classForm.groupName"
            placeholder="请输入分类名称"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类列数" prop="columnNum">
          <template>
            <el-input-number v-model="classForm.columnNum" @change="handleChange" :min="1" :max="4"
                             label="描述文字"></el-input-number>
          </template>
        </el-form-item>
        <el-form-item label="是否显隐" prop="isStatus">
          <template>
            <el-switch
              v-model="classForm.isStatus"
              active-text="显示"
              inactive-text="隐藏">
            </el-switch>
          </template>
        </el-form-item>
      </el-form>
    </CommonDialog>
    <CommonDialog v-if="previewShow" :showBtn="false" width="50%" dialogTitle="预览" @cancel="handleClose">
      <preview ref="preview"></preview>
    </CommonDialog>
  </div>
</template>
<script>
import preview from "../components/preview";
import {dictionaryBiz, templateDetail, templateSave, addField} from "@/api/reportTemplate";
import {mapGetters} from "vuex";
import {getUserInfo} from "@/api/system/user";
import CommonDialog from "@/components/CommonDialog";
export default {
  components: {
    preview,
    CommonDialog
  },
  data() {
    return {
      headTitle: '',
      intrinsicFields: ['id', 'tenant_id', 'is_deleted', 'create_user', 'create_dept', 'create_time', 'update_user', 'update_time', 'status', 'task_attribute_id'],
      formType: '',
      num1: 1,
      dataForm: {
        id: '',
        templateCode: '',
        templateName: '',
        templateStatus: '1',
        weaveUser: '',
        weaveDept: '',
        applicableLevels: '1',
        tableName: '',
        title: '',
        properties: []
      },
      emptyFieldList: [],
      addFieldVO: {
        id: '',
        templateName: '',
        fieldList: []
      },
      properties: [],
      classList: [],
      classTitle: '',
      classShow: false,
      classForm: {},
      classActive: 0,
      clickClass: true,
      group: {},
      classRules: {
        groupName: [
          {required: true, message: '请输入分类名称', trigger: 'blur'}
        ],
        columnNum: [
          {required: true, message: '请输入分类列数', trigger: 'blur'}
        ],
        isStatus: [
          {required: true, message: '请输入选择是否显隐', trigger: 'blur'}
        ],
      },
      rules: {
        // templateCode: [
        //   {required: true, message: '请输入模板编码', trigger: 'blur'},
        //   {validator: this.validateTemplateCode, trigger: 'blur'},
        // ],
        templateName: [
          {required: true, message: '请输入模板名称', trigger: 'blur'}
        ],
        templateStatus: [
          {required: true, message: '请选择状态', trigger: 'change'}
        ],
        applicableLevels: [
          {required: true, message: '请选择使用级别', trigger: 'change'}
        ]
      },
      previewShow: false,
      typeOptions: [],
      yesOrNoOptions: [],
      inputWayOptions: [],
      unit: [],
      usageLevelOption: [],
      publishStatusOption: [],
      mysqlKeywords: ["ADD", "ALTER", "ALL", "AND", "ANY", "AS", "ASC", "BETWEEN", "CASE", "CHECK", "COLUMN", "CONSTRAINT", "CREATE",
        "DATABASE", "DEFAULT", "DELETE", "DESC", "DISTINCT", "DROP", "EXEC", "EXISTS", "FROM", "HAVING", "IN", "INDEX", "JOIN", "LIKE",
        "LIMIT", "NOT", "OR", "PROCEDURE", "ROWNUM", "SELECT", "SET", "TABLE", "TOP", "UNION", "UNIQUE", "UPDATE", "VALUES", "VIEW", "WHERE"],
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit', 'alter'].includes(this.formType)) {
        result.push(
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          }
        );
        result.push(
          {
            label: "保存并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "saveBack",
          }
        );
      }
      result.push(
        {
          label: "预览",
          emit: "head-preview",
          type: "button",
        }
      );
      result.push(
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }
      );
      return result;
    },
  },
  mounted() {
    this.getDictory();
    let type = this.$route.query.type;
    this.formType = type;
    if (type == 'add') {
      this.headTitle = '填报模板新增';
      this.getNowUserInfo();
    }
    if (type == 'edit') {
      this.headTitle = '填报模板编辑';
      this.dataForm.id = this.$route.query.id;
      this.getTemplateDetail();
    }
    if (type == 'alter') {
      this.headTitle = '填报信息修改';
      this.dataForm.id = this.$route.query.id;
      this.getTemplateDetail();
    }
    if (type == 'view') {
      this.headTitle = '填报模板查看';
      this.dataForm.id = this.$route.query.id;
      this.getTemplateDetail();
    }
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    validateTemplateCode(rule, value, callback) {
      const pattern = /^[a-z_][a-z0-9_]*$/;
      if (!pattern.test(value)) {
        callback(new Error('模板编码格式错误')); // 表名无效，调用回调函数并传入错误信息。
      } else if (this.mysqlKeywords.includes(value.toUpperCase())) {
        callback(new Error('模板编码为MySQL关键字')); // 表名无效，调用回调函数并传入错误信息。
      } else {
        callback(); // 表名有效，调用回调函数继续表单验证。
      }
    },
    getDictory() {
      dictionaryBiz("report_data_type").then((res) => {
        this.typeOptions = res.data.data;
      });
      dictionaryBiz("yesandno").then((res) => {
        this.yesOrNoOptions = res.data.data;
      });
      dictionaryBiz("report_input_way").then((res) => {
        this.inputWayOptions = res.data.data;
      });
      dictionaryBiz("unit").then((res) => {
        this.unit = res.data.data;
      });
      dictionaryBiz("usage_level").then((res) => {
        this.usageLevelOption = res.data.data;
      });
      dictionaryBiz("publish_status").then((res) => {
        this.publishStatusOption = res.data.data;
      });
    },
    // 新增分类列表
    addClass() {
      this.classType = 'add';
      this.classShow = true;
      this.classTitle = "新增分类";
      this.resetClassForm();
    },
    resetDataForm() {
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields();
      })
      this.dataForm = {
        id: '',
        templateCode: '',
        templateName: '',
        templateStatus: '1',
        weaveUser: '',
        weaveDept: '',
        applicableLevels: '1',
        tableName: '',
        title: '',
        properties: []
      };
      this.classList = [];
      this.properties = [];
    },
    getNowUserInfo() {
      getUserInfo().then((res) => {
        this.dataForm.weaveUser = JSON.parse(res.data.data.realName).zh;
        this.dataForm.weaveDept = res.data.data.deptName;
      })
    },
    // 重置分类表单
    resetClassForm() {
      this.$nextTick(() => {
        this.$refs.classForm.resetFields();
      })
      this.classForm = {
        columnNum: "",
        groupName: "",
        groupCode: "",
        isStatus: true,
        properties: []
      };
    },
    // 选择分类列表
    chooseTableData(item, index) {
      this.classActive = index;
      this.clickClass = false
      this.group = item
      if (item.properties.length == 0) {
        item.properties.push({
          fieldCode: item.groupCode + "_1",
          fieldName: "",
          isEnable: "1",
          isSummary: "2",
          inputWay: "1",
          calculationRules: "",
          type: "3",
          inputLength: "",
          dictionaryCode: '',
          isStatus: '1',
          unit: '1'
        })
      }
      this.properties = item.properties;
    },
    // 编辑分类列表
    editClass(item, index) {
      this.classIndex = index;
      this.classType = 'edit';
      this.classShow = true;
      this.classTitle = "编辑分类";
      this.classForm = item;
    },
    // 删除分类列表
    deleteClass(index) {
      this.properties = [];
      this.classList.splice(index, 1);
    },
    // 新增右侧列表配置
    addTable(row, index) {
      if (this.clickClass) {
        return this.$message({
          message: "请至少选择左边一条分类",
          type: "error",
        });
      }
      let cc = this.properties.map(e => e.fieldCode.match(/\d+/g));
      let aa = []
      cc.forEach(e => {
        if (e != null) {
          e.forEach(i => {
            aa.push(parseInt(i))
          })
        } else {
          aa.push(0)
        }
      })
      this.properties.splice(index + 1, 0, {
        fieldCode: this.group.groupCode + "_" + (Math.max(...aa) + 1),
        fieldName: "",
        isEnable: "1",
        isSummary: "2",
        inputWay: "1",
        calculationRules: "",
        type: "3",
        inputLength: "",
        dictionaryCode: '',
        isStatus: '1',
        unit: '1'
      });
    },
    // 删除右侧列表配置
    deleteTable(row, index) {
      this.properties.splice(index, 1);
    },
    //显示
    displayTable(row, index) {
      row.isStatus = 1;
      this.classList[this.classActive].properties[index] = row;
    },
    //隐藏
    hideTable(row, index) {
      row.isStatus = 0
      this.classList[this.classActive].properties[index] = row;
    },
    // 提交分类列表
    submitClass() {
      this.$refs.classForm.validate((valid) => {
        if (valid) {
          if (this.classType == 'add') {
            this.classList.push(this.classForm);
            this.classActive = this.classList.length - 1

            let min = 0;
            let max = 0;
            for (let i = 0; i < this.classList.length; i++) {
              max = i;
              console.log(this.classList[i], "87")
              this.classList[i].groupCode = String.fromCharCode(64 + (max + 1))
              if (i >= 27) {
                min = i;
                this.classList[i].groupCode = String.fromCharCode(97 + (min - 26))
              }
            }
            console.log("进行输出---------------", this.classList)
            this.chooseTableData(this.classForm, this.classActive)
          } else {
            this.classList[this.classIndex].groupName = this.classForm.groupName;
            this.classList[this.classIndex].columnNum = this.classForm.columnNum;
            this.classActive = this.classIndex
            this.chooseTableData(this.classList[this.classIndex], this.classActive)
          }
          this.classShow = false;
        }
      });
    },
    // 根据ID获取填报详情
    getTemplateDetail() {
      templateDetail(this.dataForm.id).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
          res.data.data.properties.forEach(e => {
            e.isStatus = (e.isStatus == '1')
          })
          this.classList = res.data.data.properties;
          if (this.classList.length != 0) {
            this.classActive = 0;
            this.properties = this.classList[0].properties;
          }
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 获取空的新增模版信息
    getEmptyField() {
      this.classList.forEach((item) => {
        item.properties.forEach((i) => {
          if (i.id == undefined || i.id == '') {
            this.emptyFieldList.push(i);
          }
        })
      })
    },
    // 失效的模版新增模版信息
    addField() {
      if (this.emptyFieldList.length > 0) {
        this.addFieldVO.id = this.dataForm.id;
        this.addFieldVO.templateCode = this.dataForm.templateCode;
        this.addFieldVO.fieldList = this.emptyFieldList;
        addField(this.addFieldVO).then((res) => {
          if (res.data.code == 200) {
            this.emptyFieldList = [];
            this.$message({
              message: "新增字段成功",
              type: "success",
            });
          }
        })
      }
    },
    // 提交填报模板
    headSave(boolean) {
      if (this.classList.length == 0) {
        this.$message({
          message: "请填写模板信息",
          type: "warning",
        });
        return;
      }
      let List = new Array();
      this.getEmptyField();
      // 清除模板信息ID
      let duplicateFound = false;
      this.classList.forEach((item) => {
        item.id = '';
        item.properties.forEach((i) => {
          if (this.intrinsicFields.includes(i.fieldCode)) {
            this.$message.warning("模板数据项编号【" + i.fieldCode + "】不合法！");
            duplicateFound = true;
            return;
          }
          i.id = '';
          List.push(i);
        })
      })
      if (duplicateFound) {
        return;
      }
      if (!this.classListExist(List)) return
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          this.dataForm.properties = this.classList;
          this.classList.forEach(e => {
            if (e.isStatus) {
              e.isStatus = 1
            } else {
              e.isStatus = 0
            }
          })
          templateSave(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              if (boolean) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                this.dataForm = res.data.data;
                res.data.data.properties.forEach(e => {
                  e.isStatus = (e.isStatus == '1')
                })
                this.classList = res.data.data.properties;
                this.classActive = 0;
                this.properties = this.classList[0].properties;
              }
              if (this.formType == 'alter') {
                this.addField();
              }
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$loading().close();
            }
          })
        }
      });
    },
    classListExist(data) {
      let isOk = true;
      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        // if (!e.fieldCode) {
        //   this.$message({
        //     message: '请输入数据项编号',
        //     type: 'warning'
        //   });
        //   isOk = false;
        // } else if (!/^[a-z]*$/.test(e.fieldCode)) {
        //   this.$message({
        //     message: '数据项编号只能包含小写字母',
        //     type: 'warning'
        //   });
        //   isOk = false;
        // } else if (this.mysqlKeywords.includes(e.fieldCode.toUpperCase())) {
        //   this.$message({
        //     message: '数据项编号为MySQL保留字',
        //     type: 'warning'
        //   });
        //   isOk = false;
        // }
        if (!e.fieldName) {
          this.$message({
            message: '请输入数据项名称',
            type: 'error'
          });
          isOk = false
          break
        }
        if (!e.type) {
          this.$message({
            message: '请选择类型',
            type: 'error'
          });
          isOk = false
          break
        }
        if (!e.isEnable) {
          this.$message({
            message: '请选择是否必填',
            type: 'error'
          });
          isOk = false
          break
        }
        if (!e.isSummary) {
          this.$message({
            message: '请选择是否汇总',
            type: 'error'
          });
          isOk = false
          break
        }
        if (!e.inputWay) {
          this.$message({
            message: '请选择输入方式',
            type: 'error'
          });
          isOk = false
          break
        }
      }
      return isOk;
    },
    // 预览
    preview() {
      // let data = this.classList;
      //  console.log("datadata",data)
      // if (this.classList.length == 0) {
      //   this.$message({
      //     message: '请选择填写模板信息再预览',
      //     type: 'error'
      //   });
      //   return;
      // }
      let dataForm = new Object();
      let data = this.classList;
      let filter = data.filter(e => e.isStatus || e.isStatus == '1');
      filter.forEach(e => {
        e.properties = e.properties.filter(item => item.isStatus == '1')
      })
      console.log(filter, '945')
      this.previewShow = true;
      filter.forEach((item) => {
        item.properties.forEach((i) => {
          if (item.columnNum == 1) {
            item.span = 24;
          }
          if (item.columnNum == 2) {
            item.span = 12;
          }
          if (item.columnNum == 3) {
            item.span = 8;
          }
          if (item.columnNum == 4) {
            item.span = 6;
          }
          if (i.type == '1' || i.type == '2') {
            if (!dataForm[i.fieldCode]) {
              dataForm[i.fieldCode] = undefined;
            }
          } else {
            if (!dataForm[i.fieldCode]) {
              dataForm[i.fieldCode] = '';
            }
          }
          if (i.type == '5') {
            dictionaryBiz(i.dictionaryCode).then((res) => {
              i.dictionaryOption = res.data.data
            });
          }
        })
      })
      setTimeout(() => {
        this.$refs.preview.formOption = filter;
        this.$refs.preview.dataForm = dataForm;
        this.$refs.preview.previewShow = true;
        console.log(this.$refs.preview, '987')
      }, 1000)
    },
    handleClose() {
      this.previewShow = false;
      this.$refs.preview.previewShow = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.formBox {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.formTitleBox {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.leftBack {
  cursor: pointer;
  display: flex;
}

.backBox {
  position: relative;
  line-height: 28px;
}

.backBox:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dcdfe6;
}

.rightBtn {
  display: flex;
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.leftClass {
  width: calc(15% - 48px);
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 12px 24px;
}

.classDataBox {
  height: calc(100% - 34px);
  overflow: auto;
}

.classLittle {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 14px);
  border: 1px solid #bbbbbb;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 15px;
  padding: 0 6px;
}

.rightTable {
  width: 84%;
}

.classActive {
  background-color: #E9EDF3;
}

.container {
  display: flex;
  justify-content: space-between;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  //border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
</style>
